body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
main {
  text-align: center;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
img {
  width: 250px;
  margin-bottom: 2rem;
}
form {
  display: flex;
  flex-direction: column;
  width: 20rem;
  row-gap: 1rem;
}

h1 {
  font-size: 3rem;
  margin: 0 0 1rem 0;
  text-align: center;
}

label {
  font-size: 1.5rem;
  color: #666;
  font-weight: 500;
}

input {
  font-size: 1rem;
  border-radius: 0.5em;
  border: 1px solid #e5e7eb;
  padding: 0.5em;
  width: 100%;
  box-sizing: border-box;
}

button {
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  background-color: #181818;
  padding: 0.5em 1.5em;
  width: fit-content;
  border: none;
  border-radius: 0.5em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  place-self: center;
}

button:hover {
  background-color: #333;
}
header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
header nav {
  background: #000000;
  padding: 1rem 2rem;
  margin-bottom: 2rem;
  display: flex;
  gap: 3rem;
}
header nav a {
  color: white;
  text-decoration: none;
  font-weight: 800;
}

h2 {
  background-color: #b53a31;
  padding: 1rem 2rem;
  color: white;
  font-size: 1rem;
}
